import { generatePathUrl } from 'utils/urls';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const TrialButton = () => {
  const {
    t,
  } = useTranslation();

  const {
    user,
  } = useAuth();

  const {
    query: {
      spaceId,
    },
    push,
  } = useRouter();

  const handleClick = useCallback(() => {
    const path = `${generatePathUrl({
      spaceId,
    }, 'spaceId')}/_settings/_plan`;

    push(path, path, {
      shallow: true,
    });
  }, [push, spaceId,]);

  if (user?.plan && user?.plan !== 'free') {
    return null;
  }
  return <Button onClick={handleClick}>
    {t('upgrade-your-plan')}
  </Button>;
};

export default TrialButton;
