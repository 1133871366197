import { useTranslation } from 'next-i18next';
import StatusInfoCard from 'components/info/StatusInfoCard';

const ResourceNotFound = () => {
  const {
    t,
  } = useTranslation();

  return <StatusInfoCard title={t('Not-found')}>
    {t('The resource you are looking for does not exist.')}
  </StatusInfoCard>;
};

export default ResourceNotFound;
