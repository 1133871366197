import { useAuth } from '@mindhiveoy/react-auth';
import App from 'components/App';
import React from 'react';
import SpaceProvider from 'components/space/SpaceProvider';
import type { PropsWithChildren } from 'react';
import type { WithMedia } from '@shared/schema/src/common';
import type { WithNavigationControls } from 'components/layout/Navbar';
import type { WithPathParamProps } from '@shared/schema/src/pathParams';

export interface SpaceScreenProps extends WithPathParamProps, WithMedia, WithNavigationControls {
  requireAuthentication?: boolean;
  blurBackground?: boolean;
}
const SpaceScreen = ({
  children,
  requireAuthentication,
  params,
  controls,
  blurBackground,
  media,
}: PropsWithChildren<SpaceScreenProps>) => {
  const {
    authenticated,
    acquireAuthentication,
  } = useAuth();

  if (!authenticated && requireAuthentication) {
    acquireAuthentication();
    return null;
  }
  return <App
    media={media}
    blurBackground={blurBackground}
  >
    <SpaceProvider
      params={params}
      controls={controls}>
      {children}
    </SpaceProvider>
  </App>;
};

export default SpaceScreen;
