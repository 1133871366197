import { MembershipProvider } from 'components/auth/MembershipProvider';
import { SignInScreen } from 'components/auth/SignIn/SignInScreen';
import { schema } from '@shared/schema/src';
import { useRouter } from 'next/router';
import { useSpace } from 'bloc/space/SpaceBloc';
import { useTranslation } from 'next-i18next';
import DefaultScreenLayout from 'components/layout/DefaultScreenLayout';
import React, { useEffect, useMemo, useState } from 'react';
import type { PropsWithChildren } from 'react';
import type { Space } from '@shared/schema/src';
import type { UserContext } from '@mindhiveoy/auth';
import type { WithId } from '@mindhiveoy/schema';
import type { WithNavigationControls } from 'components/layout/Navbar';
import type { WithPathParamProps } from '@shared/schema/src/pathParams';

const SpaceProvider = ({
  children,
  controls,
  params,
}: PropsWithChildren<WithPathParamProps & WithNavigationControls>) => {
  const {
    query: {
      spaceId,
    } = params as any,
  } = useRouter();

  const {
    t,
  } = useTranslation();

  const [space, setSpace,] = useState<WithId<Space>>();

  const spaceBloc = useSpace();

  useEffect(() => spaceBloc.subscribe(
    ['plan',], setSpace
  ), [spaceBloc, spaceId,]);

  const userContext: UserContext = useMemo(() => {
    return {
      contextId: 'space',
      params: {
        spaceId: spaceId as string,
        planId: space?.plan ?? 'free',
      },
    };
  }, [space?.plan, spaceId,]);

  return <MembershipProvider
    userContext={userContext}
    contextDesc={schema.roleContext}
    onClosed={<div>{t('closed-state-not-implemented-yet')}</div>}
    onInvited={<div>{t('invited-state-not-implemented-yet')}</div>}
    onPending={<div>{t('pending-state-not-implemented-yet')}</div>}
    requiresAuthentication
    orRender={<SignInScreen />}
  >
    <DefaultScreenLayout controls={controls} >
      {children}
    </DefaultScreenLayout>
  </MembershipProvider>;
};

export default SpaceProvider;
