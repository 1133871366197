import { BlocQuery } from '@mindhiveoy/bloc/BlocQuery';
import { createProject } from './projectApi';
import { createUseBlocHook } from '@mindhiveoy/react-bloc';
import { validateSessionProps } from '@shared/schema/src';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { Project, SpaceParams } from '@shared/schema/src';

/**
 *
 * @template {string} MemberRoleId
 * @template {MemberShip} AppMember
 */
export class ProjectsBloc extends BlocQuery<Project, SpaceParams> {
  /**
   *
   * @param {SpaceParams} params Space id
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: SpaceParams,
    onError?: BlocErrorFunction
  ) {
    validateSessionProps(params, 'spaceId');

    const {
      spaceId,
    } = params;

    super({
      params,
      onError,
      collectionPath: `spaces/${ spaceId }/projects`,
    });
  }
  /**
   * Create a new project.
   *
   * @param {projectId} project      Project data
   * @return {WithId<Project>} A data object containing the id of the created project and project's initial data.
   */
  public createProject = async (
    project: Partial<Project> & Pick<Project, 'name'>
  ) => {
    return this._create(
      async () => {
        return await createProject({
          spaceId: this.params.spaceId,
          project: project as Project,
        });
      },
      this.onError
    );
  };
}

export const useProjects = createUseBlocHook<Project, SpaceParams, ProjectsBloc>(
  ({
    params,
    onError,
  }) => new ProjectsBloc(params, onError)
);
