/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorInfo } from 'components/canvas/ErrorInfo';
import { Show } from 'components/auth/Show';
import { getVariantDisplayName } from 'utils/variantUtils';
import { useProjects } from 'bloc/project/ProjectsBloc';
import { useSpace } from 'bloc/space/SpaceBloc';
import { useTranslation } from 'next-i18next';
import { useUserProjects } from 'bloc/project/useUserProjects';
import BackgroundFadeTop from 'components/styling/BackgroundFadeTop';
import Button from '@mui/material/Button';
import Cards from 'components/layout/Cards';
import Center from 'components/common/layout/Center';
import ContentBlock from 'components/layout/ContentBlock';
import ContentContainer from 'components/layout/ContentContainer';
import GeneralDialog from 'components/info/GeneralDialog';
import Grid from '@mui/material/Grid';
import Head from 'next/head';
import IconButton from '@mui/material/IconButton';
import LoadingIndicator from 'components/common/LoadingIndicator';
import ProjectItem from 'components/project/ProjectItem';
import React, {
  type CSSProperties, useCallback, useEffect, useMemo,
  useState
} from 'react';
import ResourceNotFound from 'components/common/ResourceNotFound';
import SettingsIcon from '@mui/icons-material/Settings';
import SmokyBackground from 'components/layout/SmokyBackground';
import SpaceScreen from 'components/space/SpaceScreen';
import TrialButton from 'components/billingAndInvoices/components/TrialButton';
import Typography from '@mui/material/Typography';
import dynamic from 'next/dynamic';
import useNavigation from 'utils/hooks/useNavigation';
import usePathParams from 'utils/hooks/usePathParams';
import type { Project, Space, SpaceParams, UserProjectInfo } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

const LazyCreateProjectForm = dynamic(() => import('../../project/CreateProjectForm'), {
  loading: () =>
    <LoadingIndicator
      fullScreen
      loaderTitle='Loading...'
    />,
});

const descriptionTextStyle: CSSProperties = {
  whiteSpace: 'pre-wrap',
};

const PageView = () => {
  const {
    t,
  } = useTranslation();

  const pathParams = usePathParams<SpaceParams>();

  const userProjects = useUserProjects(pathParams);

  const [newProjectDialogOpen, setNewProjectDialogOpen,] = useState(false);
  const [space, setSpace,] = useState<WithId<Space>>();
  const [loading, setLoading,] = useState(true);
  const [error, setError,] = useState<any>();

  const spaceBloc = useSpace(pathParams);

  const {
    pushPath: navigatePath,
  } = useNavigation();

  useEffect(() => spaceBloc.subscribe(
    ['name', 'description', 'media', 'plan',],
    (space) => {
      setSpace(space);
      setLoading(!space);
    },
    setError
  ), [spaceBloc,]);

  const handleSettingsClick = useCallback(() => {
    navigatePath('_settings');
  }, [navigatePath,]);

  const controls = useMemo(() => {
    const result = [
      <Show key="settings"
        withRoles={['admin', 'system',]}>
        <IconButton
          key="settings"
          aria-label="settings"
          color="inherit"
          onClick={handleSettingsClick}
        >
          <SettingsIcon />
        </IconButton>
      </Show>,
      <Show key="dashboard"
        withRole="system">
        <Button key="dashboard"
          href="/_dashboard">Dashboard</Button>
      </Show>,
    ];

    if (space?.plan) {
      result.splice(0, 0,
        <Show withRole='admin'
          key="trial">
          <TrialButton />
        </Show>);
    }

    return result;
  }, [handleSettingsClick, space?.plan,]);

  const handleNewProject = useCallback(() => {
    setNewProjectDialogOpen(true);
  }, []);

  if (error) {
    return <ErrorInfo error={error} />;
  }
  if (loading) {
    return <LoadingIndicator fullScreen />;
  }
  if (!loading && !space) {
    return <ResourceNotFound />;
  }

  return <>
    <Head>
      <title>{getVariantDisplayName()} - {space?.name}</title>
    </Head>
    <SpaceScreen
      media={space?.media}
      controls={controls}>
      <BackgroundFadeTop $midwayThreshold={30} />
      <ContentContainer>
        <SmokyBackground $withBottomMargin>
          <Typography
            component={'h1'}
            variant="h1"
          >{
              space?.name ?? t('welcome')
            }
          </Typography>
          <Typography
            component={'span'}
            style={descriptionTextStyle}>
            {space?.description ? <span dangerouslySetInnerHTML={{
              __html: space?.description,
            }} /> : null}
          </Typography>
        </SmokyBackground>
        <SmokyBackground $withBottomMargin>
          <Typography component={'span'}
            variant="h1">{t('Your projects')}</Typography>
          <Cards key="cards">
            <Grid container
              spacing={4}>
              {
                <Show withRoles={['admin', 'system',]}
                  orRender={<UserProjects userProjects={userProjects} />}
                >
                  <FullProjectList />
                </Show>
              }
            </Grid>
          </Cards>
        </SmokyBackground>
        <Show withRoles={['admin', 'system',]} >
          <ContentBlock>
            {userProjects.length === 0 &&
              <Typography
                component={'span'}
                variant="body1">
                {t('Start your first project to create sessions')}
              </Typography>
            }
          </ContentBlock>
          <ContentBlock>
            <Center>
              <Button
                onClick={handleNewProject}
                variant="contained" >{t('new-project')}</Button>
            </Center>
          </ContentBlock>
        </Show>
        <Show withRole='member'>
          {userProjects.length === 0 ?
            <Typography
              component={'span'}
              variant="body1">
              {t('No projects to show yet')}
            </Typography> : null
          }
        </Show>
      </ContentContainer>
      {
        newProjectDialogOpen &&
        <GeneralDialog
          open={newProjectDialogOpen}
          title={t('New project')}
          padding={2}
          size="medium"
        >
          <LazyCreateProjectForm
            onCancel={() => setNewProjectDialogOpen(false)}
          />
        </GeneralDialog>
      }
    </SpaceScreen>
  </>;
};

interface UserProjectsProps {
  userProjects: WithId<UserProjectInfo>[];
}

/**
 * List of the project that user has access to. This is the
 * case for non-admin and system users.
 *
 * @param param0
 * @returns
 */
const UserProjects = ({
  userProjects,
}: UserProjectsProps) => {
  return <>
    {userProjects.map((project) =>
      <Grid
        key={project._id}
        item
        xs={12}
        md={6}>
        <ProjectItem key={project._id}
          project={project}
        />
      </Grid>
    )}
  </>;
};

/**
 * List of all projects in the space. This is the case for
 * @returns
 */
const FullProjectList = () => {
  const projectsBloc = useProjects();

  const [projects, setProjects,] = useState<WithId<Project>[]>([]);

  useEffect(() => projectsBloc.subscribe(
    (projects = []) => {
      setProjects(projects.sort((a, b) => {
        if (!a?.name || !b?.name) {
          return 0;
        }
        return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
      }));
    },
    (error) => {
      console.error('Error loading projects', error);
    }
  ), [projectsBloc,]);

  return <>
    {projects.map((project) =>
      <Grid
        key={project._id}
        item
        xs={12}
        md={6}>
        <ProjectItem key={project._id}
          project={project}
        />
      </Grid>
    )}
  </>;
};

export default PageView;
