import { cardVariants } from '../../utils/variants';
import { generatePathUrl } from 'utils/urls';
import { motion } from 'framer-motion';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MediaCardWithIcons from 'components/layout/MediaCardWithIcons';
import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import useNavigation from 'utils/hooks/useNavigation';
import usePathParams from 'utils/hooks/usePathParams';
import type { CSSProperties } from 'react';
import type { Project, PublicMemberInfo } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export const PlaceForIcons = styled.div`
  position: absolute;
  max-height:30px;
  z-index: 100;
  right: 0px;
  top: 0px;
`;

export const IconButtons = styled(IconButton)`
  float: right;
  max-height:25px;
  max-width:25px;
  color:red;
`;

const StyledAvatar = styled(Avatar)(({
  theme,
}) => `
  width: ${theme.spacing(8)};
  height: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(1)};
`);

const Host = styled.div(({
  theme,
}) => `
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing(1)};
`);

const HostName = styled.h5`
  color: white;
  /* font-size: 125%; */
  margin: 0;
  padding: 0;
  z-index: 1;
`;

interface FacilitatorProps {
  facilitator: PublicMemberInfo;
}

const HostAvatar = ({
  facilitator,
}: FacilitatorProps): JSX.Element => {
  const {
    displayName,
    photoURL,
  } = facilitator;

  return <Host>
    <StyledAvatar alt={displayName}
      src={photoURL} />
    <HostName>{displayName}</HostName>
  </Host>;
};

export interface ProjectItemProps {
  project: Partial<WithId<Project>>;
  disabled?: boolean;
}

const itemStyle: CSSProperties = {
  display: 'flex',
  position: 'relative',
  height: '100%',
};

const tapStyle = {
  scale: 0.95,
};
const hoverStyle = {
  scale: 1.05,
};
const ProjectItem = ({
  project,
  disabled = false,
}: ProjectItemProps) => {
  const pathParams = usePathParams();

  const {
    pushPath: navigatePath,
  } = useNavigation();

  const [open, setOpen,] = useState<boolean>(false);
  const {
    name,
    description,
    media,
    facilitators,
  } = project ?? {};

  const handleProjectClick = useCallback((event: MouseEvent | TouchEvent | PointerEvent) => {
    event.stopPropagation();

    if (!project) {
      return;
    }
    const path = generatePathUrl({
      spaceId: pathParams.spaceId,
      projectId: project._id!,
    }, 'projectId');

    !disabled && navigatePath(path);
  }, [disabled, navigatePath, pathParams.spaceId, project,]);

  // const handleEditProjectClick = useCallback((event: React.MouseEvent) => {
  //   event.stopPropagation();

  //   if (!project) {
  //     return;
  //   }

  //   if (!disabled) {
  //     const path = `${generatePathUrl({
  //       ...query,
  //       projectId: project._id!,
  //     }, 'projectId')}/_settings`;

  //     push(path, path, {
  //       shallow: true,
  //     });
  //   }
  // }, [disabled, project, push, query,]);

  // const handleDeleteProjectClick = useCallback((event: React.MouseEvent) => {
  //   event.stopPropagation();
  // }, []);

  // const handleArchiveProjectClick = useCallback((event: React.MouseEvent) => {
  //   event.stopPropagation();
  // }, []);

  const handleProjectSetupClick = useCallback((event: React.MouseEvent) => {
    setOpen(!open);
    event.stopPropagation();
  }, [open,]);

  if (!project) {
    return null;
  }

  return <motion.div
    key={`p_${project?._id}`}
    variants={cardVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
    layout
    style={itemStyle}
    layoutId={`project_${project._id}`}
    whileTap={tapStyle}
    whileHover={hoverStyle}
    onTap={handleProjectClick}
    onContextMenu={handleProjectSetupClick}
  >
    <MediaCardWithIcons
      openIcons={open}
      media={media}
      title={name}
      description={description}
      elements={facilitators && facilitators.map((facilitator, index) =>
        <HostAvatar key={index}
          facilitator={facilitator} />
      )}
    />
  </motion.div>;
};

export default ProjectItem;
