/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISR_DEFAULT_REVALIDATE_INTERVAL } from 'common-settings';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { staticPathsForScope } from 'utils/staticSiteUtils';
import PageContextProvider from 'components/common/layout/page/PageContextProvider';
import PageView from 'components/space/SpaceView';
import React from 'react';
import type { SpaceParams } from '@shared/schema/src';

const SpacePage = () =>
  <PageContextProvider pathLevel='space'>
    <PageView />
  </PageContextProvider>;

// Static site generation (SSG) for /[spaceId] dynamic route
// This is needed for more than 10x performance improvement
export const getStaticProps = async ({
  params,
  locale,
}: any) => {
  return {
    props: {
      ...await serverSideTranslations(locale, [
        'common',
        'dashboard',
      ]),
      params: params as SpaceParams,
    },
    revalidate: ISR_DEFAULT_REVALIDATE_INTERVAL,
  };
};

export const getStaticPaths = staticPathsForScope('space');

export default SpacePage;
