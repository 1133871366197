import { generatePathUrl, isEditModeKey, resolveRelativeDirectoryPath } from 'utils/urls';
import { symbol } from 'd3';
import { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import type { PathParams } from '@shared/schema/src';

export const ADD_SOURCE = symbol();

const useNavigation = () => {
  const {
    query,
    asPath,
    push,
    replace,
  } = useRouter();

  const isShifting = useRef(false);

  const resolvePath = useCallback((newPath: string) => {
    if (isShifting.current) {
      return;
    }
    isShifting.current = true;

    return newPath.startsWith('/') ? newPath :
      resolveRelativeDirectoryPath(asPath, newPath);
  }, [asPath,]);

  const pushPath = useCallback((newPath: string) => {
    const path = resolvePath(newPath);
    if (!path) {
      return;
    }
    push(path, path, {
      shallow: true,
    });
  }, [push, resolvePath,]);

  const replacePath = useCallback((newPath: string) => {
    const path = resolvePath(newPath);
    if (!path) {
      return;
    }
    replace(path, path, {
      shallow: true,
    });
  }, [replace, resolvePath,]);

  const pushByParams = useCallback(<T = string>(pathParams: PathParams,
    leaf: keyof PathParams,
    command?: T | typeof ADD_SOURCE
  ) => {
    const path = generatePathUrl(pathParams, leaf, command);
    push(path, path, {
      shallow: true,
    });
  }, [push,]);

  const replaceByParams = useCallback(<T = string>(pathParams: PathParams,
    leaf: keyof PathParams,
    command?: T | typeof ADD_SOURCE
  ) => {
    if (command === ADD_SOURCE) {
      const keys = Object.keys(query).filter(isEditModeKey);
      if (keys.length) {
        command = `source=${keys[0]}` as T;
      }
    }
    const path = generatePathUrl(pathParams, leaf, command);
    replace(path, path, {
      shallow: true,
    });
  }, [query, replace,]);

  return {
    /**
     * Navigate to a to the path within the current browser domain updating the browser history. When the path is relative,
     * it is resolved from the current path. When the path is absolute, it is navigated to directly
     * inside of the current domain.
     */
    pushPath,
    /**
     * Replace the current path with a new path within the current browser domain. When the path is relative,
     * it is resolved from the current path. When the path is absolute, it is navigated to directly
     * inside of the current domain.
     */
    replacePath,
    /**
     * Navigate to a path based on the path params.
     *
     * @param {PathParams}        params  Path params
     * @param {keyof PathParams}  leaf    Depth of the based on `QueryPropsDepthMap`.
     * @param {T}                 command Command included in url if any
     */
    pushByParams,
    /**
     * Replace the current path with a path based on the path params.
     *
     * @param {PathParams}        params  Path params
     * @param {keyof PathParams}  leaf    Depth of the based on `QueryPropsDepthMap`.
     * @param {T}                 command Command included in url if any
     */
    replaceByParams,
  };
};

export default useNavigation;
