
import {
  DescriptionAreaForMediaCard, LeftForMediaCard, RightForMediaCard, StyledActionsForMediaCard, TitleForMediaCard
} from './MediaCard';
import type { MediaCardProps } from './MediaCard';
// import { IconButtons } from 'components/project/ProjectItem';
// import ArchiveIcon from '@mui/icons-material/Archive';
import BackgroundFade from 'components/styling/BackgroundFade';
import CardItem from './CardItem';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import MediaContainer from './MediaContainer';
import React from 'react';
// import styled from '@emotion/styled';

// const PlaceForIcons = styled.div`
//   flex: 1;
//   position: absolute;
//   max-height:30px;
//   right: 0px;
//   top: 0px;
// `;

export interface MediaCardWIthIconsProps extends MediaCardProps {
  openIcons?: boolean;
  // onDeleteClick?: React.MouseEventHandler<unknown>;
  // onArchiveClick?: React.MouseEventHandler<unknown>;
  // onEditClick?: React.MouseEventHandler<unknown>;
}

const MediaCardWithIcons = ({
  // openIcons = false,
  title,
  // disabled = false,
  // description,
  elements,
  media,
  // addCard = false,
  // onArchiveClick,
  // onDeleteClick,
  // onEditClick,
  onClick,
}: MediaCardWIthIconsProps): JSX.Element => {
  // const parsedText = useMemo(() => parseRawTextToReactPresentation(description),
  //   [description,]);

  return <CardItem>
    <MediaContainer
      test-id="media-container"
      media={media}
    >
      {
        // openIcons &&
        //   <PlaceForIcons>
        //     <IconButtons onClick={onArchiveClick}>
        //       <ArchiveIcon fontSize='large'/>
        //     </IconButtons>
        //     <IconButtons onClick={onDeleteClick}>
        //       <DeleteIcon fontSize='large'/>
        //     </IconButtons>
        //     <IconButtons onClick={onEditClick}>
        //       <EditIcon fontSize='large'/>
        //     </IconButtons>
        //   </PlaceForIcons>
      }
      <BackgroundFade onClick={onClick}>
        <DescriptionAreaForMediaCard>
          {/* {
            addCard &&
              <Center>
                <AddIcon fontSize='large'/>
                <TitleForMediaCard gutterBottom variant="h2" >
                  create new
                </TitleForMediaCard>
              </Center>
          } */}
          {/* {description && <ProjectDescription gutterBottom variant="h5" >
          {parsedText}
        </ProjectDescription>} */}
        </DescriptionAreaForMediaCard>
        <StyledActionsForMediaCard>
          <LeftForMediaCard>
            <TitleForMediaCard gutterBottom
              variant="h2" >
              {title}
            </TitleForMediaCard>
          </LeftForMediaCard>
          <RightForMediaCard>
            {elements}
          </RightForMediaCard>
        </StyledActionsForMediaCard>
      </BackgroundFade>
    </MediaContainer>
  </CardItem>;
};

export default MediaCardWithIcons;
